import { Box, Button, Checkbox, colors, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import React, { useContext, useEffect, useState } from "react";
import { useCallback } from "react";
import { useNavigate } from "react-router";
import CustomInput from "./CustomInput";
import { BrowserRouter, Route } from 'react-router-dom';
import ApiBase from "../utilities/api/ApiBase";
import { links } from "../utilities/api/links";
import AlertTemplate from "./AlertTemplate";
import { clickedExchangeNameStyle, exchangeNameStyle, loggedInCircles } from "../styles/style";
import TitleBox from "./TitleBox";

const SigninPage: React.FC = () => {
	const [firstLoginSuccess, setFirstLoginSuccess] = useState(false)
	const [otpLoginSuccess, setOtpLoginSuccess] = useState(false)
	const [clickedExchange, setClickedExchange] = useState<string>("trive")
	const [customerNo, setCustomerNo] = useState<string>()
	const [password, setPassword] = useState<string>()
	const [otp, setOtp] = useState<string>()
	const [errorData, setErrorData] = useState<any>()


	const loginWithoutOtp = () => {
		let url = "";
		console.log("clickedExchange: ", clickedExchange)
		console.log("clickedExchange: ", clickedExchange == "osmanliYatirim")
		if (clickedExchange == "infoYatirim") {
			url = `${links.infoYatirimLogin}?username=${customerNo}&password=${password}`
		}
		else if (clickedExchange == "denizYatirim") {
			url = `${links.denizYatirimLogin}?username=${customerNo}&password=${password}`
		}
		else if (clickedExchange == "osmanliYatirim") {
			url = `${links.osmanliYatirimLogin}?username=${customerNo}&password=${password}`
		}
		else {
			url = `${links.triveLogin}?username=${customerNo}&password=${password}`
		}
		console.log("url: ", url)

		ApiBase.Get({
			place: 'SignInPage - loginWithoutOtp',
			url: url,
			body: {},
			successFunction: (res: any) => {
				if (res.status == "success") {
					setFirstLoginSuccess(true)
				}
				else {
					let data = {
						alert: { type: "warning", closable: true },
						message: res.message,
						showIcon: true
					}
					setErrorData(data)
				}
			},
			errorFunction: (res: any) => {
				let data = {
					alert: { type: "warning", closable: true },
					message: "Error! Contact with destek@uzmandata.com",
					showIcon: true
				}
				setErrorData(data)
			},
			exceptionFunction: (res: any) => {
				let data = {
					alert: { type: "warning", closable: true },
					message: "Error! Contact with destek@uzmandata.com",
					showIcon: true
				}
				setErrorData(data)
			}
		});

	}

	const loginWithOtp = () => {
		let url = "";
		if (clickedExchange == "infoYatirim") {
			url = `${links.infoYatirimLogin}?username=${customerNo}&password=${password}&otp=${otp}`
		}
		else if (clickedExchange == "denizYatirim") {
			url = `${links.denizYatirimLogin}?username=${customerNo}&password=${password}&otp=${otp}`
		}
		else if (clickedExchange == "osmanliYatirim") {
			url = `${links.osmanliYatirimLogin}?username=${customerNo}&password=${password}&otp=${otp}`
		}
		else {
			url = `${links.triveLogin}?username=${customerNo}&password=${password}&otp=${otp}`
		}

		ApiBase.Get({
			place: 'SignInPage - loginWithOtp',
			url: url,
			body: {},
			successFunction: (res: any) => {
				if (res.status == "success") {
					setOtpLoginSuccess(true)
					setFirstLoginSuccess(false)
				}
				else {
					let data = {
						alert: { type: "warning", closable: true },
						message: res.message,
						showIcon: true
					}
					setErrorData(data)
				}
			},
			errorFunction: (res: any) => {
				let data = {
					alert: { type: "warning", closable: true },
					message: "Error! Contact with destek@uzmandata.com",
					showIcon: true
				}
				setErrorData(data)
			},
			exceptionFunction: (res: any) => {
				let data = {
					alert: { type: "warning", closable: true },
					message: "Error! Contact with destek@uzmandata.com",
					showIcon: true
				}
				setErrorData(data)
			}
		});

	}

	const changeCustomerNo = (data: string) => {
		setCustomerNo(data)
	}
	const changePassword = (data: string) => {
		setPassword(data)
	}
	const changeOtp = (data: string) => {
		setOtp(data)
	}

	return (
		<Grid container height="90vh">
			<Grid
				xs={12}
				sm={12}
				md={6}
				lg={6}
				xl={6}
				mt={20}
				minHeight={550}
				sx={{
					boxShadow: {
						xs: "",
						sm: "",
						md: "15px 2px 5px -5px",
						lg: "15px 2px 5px -5px",
						xl: "15px 2px 5px -5px",
					},
				}}
			>
				<Box
					sx={{
						backgroundColor: "rgba(0, 24, 57, 0.2)",
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						height: "100%",
						borderRadius: {
							xs: "30px",
							sm: "30px",
							md: "30px 0 0 30px",
							lg: "30px 0 0 30px",
							xl: "30px 0 0 30px",
						},
					}}
				>
					<Box width="50%">
						<Box display="flex" flexDirection="column" alignItems="center" style={{ marginBottom: 40, marginTop: 40 }}>

							<div style={{ display: "flex", flexDirection: "row" }}>
								<div onClick={() => setClickedExchange("trive")}>
									<Box
										sx={clickedExchange == "trive" ? clickedExchangeNameStyle : exchangeNameStyle}
									>
										<Typography variant="h6" fontWeight="bold" color={clickedExchange == "trive" ? "white" : "black"}>
											Trive Yatırım
										</Typography>
									</Box>
								</div>
								<div onClick={() => setClickedExchange("denizYatirim")}>
									<Box
										sx={clickedExchange == "denizYatirim" ? clickedExchangeNameStyle : exchangeNameStyle}
									>
										<Typography variant="h6" fontWeight="bold" color={clickedExchange == "denizYatirim" ? "white" : "black"}>
											Deniz Yatırım
										</Typography>
									</Box>
								</div>
							</div>
							<div style={{ display: "flex", flexDirection: "row" }}>
								{/* <div onClick={() => setClickedExchange("osmanliYatirim")}>
									<Box
										sx={clickedExchange == "osmanliYatirim" ? clickedExchangeNameStyle : exchangeNameStyle}
									>
										<Typography fontWeight="bold" color={clickedExchange == "osmanliYatirim" ? "white" : "black"}>
											Osmanlı Yatırım (BETA)
										</Typography>
									</Box>
								</div> */}
								<div onClick={() => setClickedExchange("infoYatirim")}>
									<Box
										sx={clickedExchange == "infoYatirim" ? clickedExchangeNameStyle : exchangeNameStyle}
									>
										<Typography variant="h6" fontWeight="bold" color={clickedExchange == "infoYatirim" ? "white" : "black"}>
											İnfo Yatırım
										</Typography>
									</Box>
								</div>
							</div>

							<Typography color="white" fontWeight="bold" sx={{ textAlign: 'center', marginTop: 2, marginBottom: 0 }} mt={7} mb={1}>
								Borsa İstanbul otomatik al sat botunuzu şimdi aktifleştirin!
							</Typography>
						</Box>
						<div style={{ marginTop: 20, marginBottom: 20 }}>
							{errorData && <AlertTemplate data={errorData} />}
						</div>

						{otpLoginSuccess &&
							<Typography color="white" fontWeight="bold" sx={{ textAlign: 'center', marginTop: 10, marginBottom: 0 }} mt={7} mb={1}>
								Giriş başarılı! Bot aktifleştirildi, teşekkürler.
							</Typography>
						}

						{/* INPUTS */}
						{!firstLoginSuccess && !otpLoginSuccess &&
							<>
								<CustomInput
									label="Müşteri Numarası"
									placeholder="Müşteri numaranızı giriniz..."
									isIconActive={false}
									onChangeData={(state: boolean, data: string) => state && changeCustomerNo(data)}
								/>
								<CustomInput
									label="Şifre"
									placeholder="Şifrenizi giriniz..."
									isIconActive={true}
									onChangeData={(state: boolean, data: string) => state && changePassword(data)}
								/>
								<Button
									onClick={loginWithoutOtp}
									variant="contained"
									fullWidth
									sx={{ mt: 2, boxShadow: `0 0 20px ${colors.green[500]}` }}
								>
									GİRİŞ YAP
								</Button>
							</>
						}
						{firstLoginSuccess &&
							<>
								<Typography color="white" fontWeight="bold" sx={{ textAlign: 'center', marginTop: 2, marginBottom: 0 }} mt={7} mb={1}>
									Telefonunuza gelen kodu giriniz.
								</Typography>
								<CustomInput
									label="6 haneli kod"
									placeholder="6 haneli kodu giriniz..."
									isIconActive={false}
									onChangeData={(state: boolean, data: string) => state && changeOtp(data)}
								/>
								<Button
									onClick={loginWithOtp}
									variant="contained"
									fullWidth
									sx={{ mt: 2, boxShadow: `0 0 20px ${colors.green[500]}` }}
								>
									GÖNDER
								</Button>
							</>
						}
					</Box>
				</Box>
			</Grid>
			<TitleBox />
		</Grid>
	);
};

export default SigninPage;
