import { colors } from "@mui/material";
const clickedExchangeNameStyle = {
    mr: "10px",
    ml: "10px",
    mt: "10px",
    width: "150px",
    height: "50px",
    bgcolor: "primary.main",
    borderRadius: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: `0 0 20px ${colors.green[500]}`,
}
const exchangeNameStyle = {
    mr: "10px",
    ml: "10px",
    mt: "10px",
    width: "150px",
    height: "50px",
    bgcolor: `${colors.grey[200]}`,
    borderRadius: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
}
const loggedInCircles = {
    position: 'absolute',
    top: 2,
    right: 13,
    width: '15px',
    height: '15px',
}
export {
    clickedExchangeNameStyle,
    exchangeNameStyle,
    loggedInCircles
};