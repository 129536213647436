export const amazonServerUrl: string = 'https://webhook.uzmandata.com'
//export const amazonServerUrl: string = 'http://localhost:3535'
export const links = {
    infoYatirimLogin: '/info-yatirim-login',
    triveLogin: '/trive-login',
    denizYatirimLogin: '/deniz-yatirim-login',
    osmanliYatirimLogin: '/osmanli-yatirim-login',
    infoYatirimOverall: '/info-yatirim-overall',
    triveOverall: '/trive-overall',
    infoYatirimTeminatAktarma: "info-yatirim-teminat-aktarma",
    triveTeminatAktarma: "trive-teminat-aktarma",
    infoYatirimHisseMenkulList: "info-yatirim-hisse-menkul-list",
    triveHisseMenkulList: "trive-hisse-menkul-list",
    openPosition: "webhook-bist",
    infoYatirimPortfoy: "info-yatirim-portfoy",
    trivePortfoy: "trive-portfoy",
    getIpAddress: "get-ip-address",
    sendSMS: "send-sms",
    getLogs: "get-logs",
}