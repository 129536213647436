import { VisibilityOff } from "@mui/icons-material";
import {
	Box,
	IconButton,
	InputAdornment,
	InputBase,
	Paper,
	Typography,
} from "@mui/material";
import { colors } from "../theme";
import { useEffect, useState } from "react";

const CustomInput: React.FC<{
	isIconActive: boolean;
	label: string;
	placeholder: string;
	onChangeData: Function;
}> = ({ isIconActive, label, placeholder, onChangeData }) => {
	const [isPassword, setIsPassword] = useState(false)
	useEffect(() => {
		setIsPassword(isIconActive)
	}, [isIconActive])
	return (
		<Box
			display="flex"
			flexDirection="column"
			alignContent="center"
			justifyContent="flex-start"
			mb={2}
		>
			<Box display="flex" flexDirection="column" justifyContent="flex-start">
				<Typography color="white" pb={1}>
					{label}
				</Typography>
				<Paper
					sx={{
						background: colors.input[500],
						width: "100%"
					}}
				>
					<InputBase
						placeholder={placeholder}
						fullWidth
						type={isPassword ? "password" : "text"}
						sx={{
							bgcolor: colors.input[500],
							p: 1,
							borderRadius: "5px",
						}}
						onChange={value => onChangeData(true, value.target.value)}
						endAdornment={
							isIconActive && (
								<InputAdornment position="end" sx={{ pr: 1 }}>
									<IconButton edge="end" onClick={() => setIsPassword(!isPassword)}>
										<VisibilityOff />
									</IconButton>
								</InputAdornment>
							)}
					/>
				</Paper>
			</Box>
		</Box>
	);
};

export default CustomInput;
