import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

const TitleBox: React.FC = () => {
	return (
		<Grid xs={0} sm={0} md={6} lg={6} xl={6} mt={20} minHeight={550}>
			<Box
				sx={{
					backgroundImage: `linear-gradient(135deg, rgba(0, 255, 60, 0.3) , rgba(0, 157, 255, 0.3))`,
					padding: "20px",
					display: {
						xs: 'none',
						sm: 'none',
						md: 'flex',
						lg: 'flex',
						xl: 'flex'
					},
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					height: "100%",
					borderRadius: "0px 30px 30px 0",
				}}
			>
				<Box
					display="flex"
					flexDirection="column"
					justifyContent="center"
					alignItems="flex-start"
				>
					<Typography variant="h4" fontWeight="bold" color="whitesmoke" mb={3}>
						Şimdi Aramıza <br /> Katıl!
					</Typography>
					<Typography variant="body1" fontWeight="" color="whitesmoke">
						Uzmandata teknik analiz yapma ve otomatik al sat botu kurma işini, kodlama bilgisi ve teknik yeterliliğe sahip 
						belirli bir kitlenin elinden alıp tüm halka yaymaya çalışan bir Türk girişimdir. Şimdi sen de Uzmandata ailesine katıl!
					</Typography>
					<br></br>
					<a href="https://www.uzmandata.com/ucretsiz/" target="_blank">
						<Typography variant="body1" fontWeight="bold" color="whitesmoke">
							https://www.uzmandata.com/ucretsiz/
						</Typography>
					</a>
				</Box>
			</Box>
		</Grid>
	);
};

export default TitleBox;
